body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  background-color: rgba(3,13,34,255);
  color: white;
  font-size: 3rem;
  min-width: 100%
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.home {
  position: relative;
}

.start,
.devwork,
.about,
.contact {
  padding: 1rem;
}

nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100vw;
  height: 3em;
  border-bottom: 1px solid rgb(90, 245, 250);
  background-color: rgba(3,13,34,255);
  z-index: 999;
  animation: name-slide 1.5s forwards;
  animation-delay: 3s;
  top: -15rem;
  position: relative;
}

.my-name {
  float: left;
  display: flex;
  font-size: 1.5em;
  margin-left: 1rem
}

.menu-button {
  margin-right: 1rem;
}
.menu-stuff-one, .menu-stuff-two, .menu-stuff-three {
  background-color: white;
  height: .2em;
  width: 1.2em;
  margin: .15em;
}

@keyframes name-slide {
  0% {
    top: -25rem;
    position: relative;
  }
  99%{
    top: 0;
    position: relative;
  }
  100% {
    top: 0;
    position: sticky;
  }
}

.nav-items {
  flex-wrap: wrap;
  position: absolute;
  flex-direction: column;
  align-items: center;
  top: 2em;
  align-content: space-around;
  margin-bottom: 0;
  padding: 0;
  width: 100%;
}

.nav-items li {
  display: flex;
  align-items: center;
  border: 1px solid cyan;
  width: 99.9%;
  height: 6rem;
  background-color: rgba(3,13,34,255);
}

.hidden-nav {
  /* display: none; */
  /* flex-direction: column; */
  top: -30rem;
  position: absolute;
}

.start-nav,
.dev-work-nav,
.music-nav,
.about-nav,
.contact-nav {
  margin-left: 0px;
  font-size: larger;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
}

.start-nav:hover,
.dev-work-nav:hover,
.music-nav:hover,
.about-nav:hover,
.contact-nav:hover {
  cursor: pointer;
}

/* .contact-nav {
  padding-right: 1rem;
} */

/* BEGINNING OF START COMPONENT */ 

.start {
  display: flex; 
  flex-direction: column-reverse;
  justify-content: center; 
  height: 75vh;
  flex-wrap: wrap; 
  margin: 0 5% 0 5%;
}

/* .start-one, .start-two, .start-img {
  width: 40rem;
} */

.start-one .start-two {
  font-size: xx-large;
}

.start-img img {
  width: 2.5rem;
  opacity: 1;
  transition: opacity 0.5s ease-out;
  margin-left: 1rem;
}

.slide-in {
  position: relative;
  left: -200rem; 
  animation: slide-in 1.5s forwards;
}

.slide-in h3, .slide-in p {
  margin: 0;
  margin-bottom: 1rem;
  height: 100%;
}

.slide-in:nth-child(2) {
  animation-delay: 1s; 
}

.slide-in:nth-child(3) {
  animation-delay: 2s; 
}

@keyframes slide-in {
  0% {
    left: -200rem; 
  }
  100% {
    left: 0; 
  }
}

.start-software-engineer, 
.start-game-developer, 
.start-musician {
  color: rgb(0, 255, 149);
  font-size: 4rem;
}

.start-software-engineer:hover, 
.start-game-developer:hover, 
.start-musician:hover {
  font-size: 4rem;
  transition: font-size 0.75s ease-in-out;
}

.invisible {
  opacity: 0 !important;
}

.phase-in, .my-image {
  animation: phase-in 1s forwards;
  animation-delay: 3s;
  opacity: 0;
}

.phase-in {
  width: 80%;
}

.start-text {
  display: flex;
  flex-direction: column;
  align-content: space-around;
  flex-wrap: wrap;
}

@keyframes phase-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.my-image-container {
  display: flex;
  align-items: center;
  position: relative;
  height: 20%;
  justify-content: space-around;
}

.my-image {
  display: flex; 
  flex-direction: column;
  justify-content: center; 
  position: absolute;
  width: 20rem;
  border-radius: 20rem;
  float: right;
}

.about-start {
  position: relative;
}

/* END OF START COMPONENT */ 

/* BEGIN PROJECTS */

.devwork {
  animation: dev-slide 1s forwards;
  opacity: 0;
  display: grid;
  grid-template-columns: repeat(3, min-content);
  grid-gap: 1rem;
  margin: 5rem 0 5rem 0;
}

.project-banner, 
.about-banner, 
.contact-banner {
  position: absolute;
  font-size: 1.2em;
  width: 95%;
  margin: -3% 1% 0 2%;
  border-bottom: 1px solid cyan;
  color: cyan;
}

.project-grid {
  margin: 1rem 0 0 2rem;
  display: grid;
  /* grid-template-columns: repeat(2, 48vw); */
  grid-gap: 1rem;
  /* max-width: 95vw; */
}

.project-buttons {
  position: absolute;
  margin: 6rem 0 0 2rem;
  font-size: 2.6rem;
}

.project-button:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.project-button::after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: rgb(0, 255, 149);
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.project-button {
  display: inline-block;
  position: relative;
  color: rgb(0, 255, 149);
  margin-right: 1rem;
}

.project-button:hover {
  cursor: pointer;
}

@keyframes dev-slide {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.project-card {
  border: 1px solid white;
  border-radius: 0.5rem;
  padding-bottom: 1rem;
  width: 95vw;
  font-size: 2rem;
  box-shadow: 0px 0px 14px rgba(255, 255, 255, 0.3);
}

.project-card-top {
  display: flex;
}

.project-title-container {
  width: 100%;
}

.project-card-title {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.project-card-img {
  width: 35%;
  margin-left: 1vw;
}

.project-card-img img {
  width: 100%;
}

.project-card-info {
  animation: dev-slide 1s forwards;
  opacity: 0;
}

p, h3 {
  margin-inline: 1rem;
}

a {
  color: white;
}

h3 {
  font-weight: 420;
}

button {
  border: 1px solid white;
  border-radius: 0.5rem;
  color: white;
  background-color: rgba(3,13,34,255);
  margin-left: 2rem;
  transition: all 0.2s ease-in-out;
  font-size: 2rem;
}

button a {
  text-decoration: none;
  /* font-size: 100%; */
}

button:hover {
  color: rgb(0, 247, 255);
  border-color: rgb(0, 247, 255);
  /* font-size: 140%; */
}

/* ABOUT SECTION */

.about {
  margin: 5rem 0 5rem 0;
}

.about-paragraphs {
  width: 95%;
  margin-left: 2rem;
}

.contact {
  display: flex;
  flex-direction: column;
  height: 10rem;
}

.contact-buttons {
  display: flex;
  padding-top: 2rem;
  flex-direction: row;
  justify-content: space-evenly;
}

.contact img {
  margin-left: 2rem;
}

/* END ABOUT SECTION */

@media only screen and (min-width: 1025px) {
  .start {
    margin: 0;
    align-content: center;
    justify-content: space-around;
  }
  body {
    font-size: 2rem;
  }
  .start-text {
    width: 80%;
  }
  .start-one, .start-two {
    max-width: fit-content;
  }
  .my-image {
    width: 12rem;
  }
}

@media only screen and (min-width: 600px) {

}

/* @media (min-width:320px)  { smartphones, portrait iPhone, portrait 480x320 phones (Android) } */
/* @media (min-width:480px)  { smartphones, Android phones, landscape iPhone } */
/* @media (min-width:600px)  { portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) } */
/* @media (min-width:801px)  { tablet, landscape iPad, lo-res laptops ands desktops } */
/* @media (min-width:1025px) { big landscape tablets, laptops, and desktops } */
/* @media (min-width:1281px) { hi-res laptops and desktops } */